<template>
  <div>
    <div
      v-for="(fabric, index) in formData"
      :key="`fabric-${index}`">
      <v-row>
        <v-col
          cols="12"
          md="6">
          <h3 class="mt-1">
            {{ `Fabric #${index + 1}` }}
          </h3>
          <gw-drop-file
            v-model="fabric.imageUrls"
            :multiple="true"
            need-compress
            class="fabric-drop-file"
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            :limit="10"
            label-bold />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-row class="mt-5">
            <!-- <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.sketchId"
                label="Sketch Id"
                class="uppercase"
                hide-details
                outlined
                dense
                :rules="isFormValid(index)" />
            </v-col> -->
            <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.hangId"
                label="Hang Id*"
                class="uppercase"
                hide-details
                outlined
                dense
                :rules="isFormValid(index)" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.fabricNo"
                label="Fabric Id*"
                class="uppercase"
                hide-details
                outlined
                dense
                :rules="isFormValid(index)" />
            </v-col>
            <v-col cols="6">
              <v-combobox
                v-model.trim="fabric.factory"
                :items="factory"
                label="Factory*"
                outlined
                deletable-chips
                small-chips
                dense
                hide-details
                :rules="isFormValid(index)"
                class="uppercase" />
            </v-col>
            <!-- <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.collectionName"
                label="Collection"
                class="uppercase"
                hide-details
                outlined
                dense />
            </v-col> -->
            <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.size"
                label="Size"
                class="uppercase"
                hide-details
                outlined
                dense />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="fabric.color"
                label="Color"
                class="uppercase"
                hide-details
                outlined
                dense />
            </v-col>
            <v-col
              v-if="!isSketch"
              cols="6">
              <v-text-field
                v-model.number="fabric.amount"
                type="number"
                label="Amount"
                hide-details
                outlined
                :min="0"
                dense />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="fabric.unitPrice"
                type="number"
                label="Unit Price"
                hide-details
                outlined
                :min="0"
                dense />
            </v-col>
            <v-col cols="6">
              <v-radio-group
                v-model.trim="fabric.currency"
                label="Currency"
                row
                hide-details
                dense
                class="mt-1">
                <v-radio
                  label="บาท"
                  value="thb"
                  color="secondary" />
                <v-radio
                  label="หยวน"
                  value="cny"
                  color="secondary" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model.trim="fabric.note"
                label="Note"
                dense
                hide-details
                outlined />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h3 class="mt-2">
        Patterns
      </h3>
      <v-row
        v-for="(pattern, indexPattern) in fabric.patterns"
        :key="`pattern-${indexPattern}`">
        <v-col
          cols="12"
          md="6">
          <gw-drop-file
            v-model="pattern.imageUrls"
            :multiple="true"
            need-compress
            class="fabric-drop-file"
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            :limit="10"
            label-bold />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="mt-5">
          <v-row>
            <v-col cols="10">
              <v-textarea
                v-model.trim="pattern.note"
                label="Note"
                dense
                hide-details
                outlined />
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="fabric.patterns.length > 1"
                color="error"
                class="delete-fabric-btn mr-2"
                @click="deletePattern(index, indexPattern)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
        </v-col>
        <v-col cols="6">
          <v-btn
            color="error"
            class="delete-fabric-btn mr-2"
            @click="deleteFabric(index)">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            class="delete-fabric-btn mr-2"
            @click="selectFabric(index)">
            <v-icon class="mr-1">
              mdi-tshirt-crew
            </v-icon>
            เลือกผ้า
          </v-btn>
          <v-btn
            class="delete-fabric-btn"
            @click="addMorePattern(index)">
            <v-icon class="mr-1">
              mdi-plus
            </v-icon>
            เพิ่ม Pattern
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-btn
      class="my-4"
      small
      @click="addMoreFabric()">
      <v-icon left>
        mdi-plus
      </v-icon>
      เพิ่มผ้า
    </v-btn>
    <fabric-list-modal
      v-model.trim="dialog"
      @on-select="onSelected($event)"
      @on-close="closeModal()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FabricsFactoryProvider from '@/resources/FabricsFactoyProvider'
import FabricListModal from './FabricListModal.vue'

const FabricsFactoryService = new FabricsFactoryProvider()

export default {
  components: { FabricListModal },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showAmount: {
      type: Boolean,
      default: false
    },
    isSketch: {
      type: Boolean,
      default: false
    },
    formValidates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedIndex: -1,
      dialog: false,
      factoryData: []
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },
    factory: {
      get () {
        const results = this.factoryData.map((item) => item.name)
        return results
      },
      set (newVal) {
        const newValue = newVal[0] ? newVal[0].trim().toLowerCase() : ''
        this.formData.factory = newValue
      }
    }
  },
  created () {
    this.getFabricFactory()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    selectFabric (index) {
      this.selectedIndex = index
      this.dialog = true
    },
    onSelected (fabric) {
      const _fabric = JSON.parse(JSON.stringify(fabric))
      const payload = JSON.parse(JSON.stringify(this.formData))

      payload[this.selectedIndex] = {
        sketchId: this.formData[this.selectedIndex]?.sketchId || '',
        hangId: _fabric.hangId,
        fabricNo: _fabric.fabricNo,
        imageUrls: _fabric.imageUrls,
        factory: _fabric.factory,
        size: _fabric.size,
        color: _fabric.color,
        note: this.formData[this.selectedIndex]?.note || '',
        collectionName: this.formData[this.selectedIndex]?.collectionName || '',
        amount: 0,
        currency: _fabric.currency,
        unitPrice: _fabric.unitPrice,
        patterns: this.formData[this.selectedIndex]?.patterns || _fabric.patterns || []
      }

      this.formData = [...payload]
      this.$emit('input', payload)

      this.closeModal()
    },
    closeModal () {
      this.selectedIndex = -1
      this.dialog = false
    },
    addMoreFabric () {
      this.formData.push({
        hangId: '',
        fabricNo: '',
        imageUrls: [],
        factory: '',
        size: '',
        color: '',
        note: '',
        collectionName: '',
        amount: 0,
        currency: 'thb',
        unitPrice: 0,
        patterns: [{
          note: '',
          imageUrls: []
        }]
      })
    },
    addMorePattern (index) {
      const tmpData = [...this.formData]

      tmpData[index].patterns.push({
        imageUrls: [],
        note: ''
      })

      this.formData = tmpData
    },
    deleteFabric (index) {
      if (this.formData.length === 1) {
        this.formData.splice(index, 1, {
          hangId: '',
          fabricNo: '',
          imageUrls: [],
          factory: '',
          size: '',
          color: '',
          note: '',
          collectionName: '',
          amount: 0,
          currency: 'thb',
          unitPrice: 0,
          patterns: [{
            note: '',
            imageUrls: []
          }]
        })
      } else {
        this.formData.splice(index, 1)
      }
    },
    deletePattern (index, patternIndex) {
      const tmpData = [...this.formData]

      tmpData[index].patterns.splice(patternIndex, 1)

      this.formData = tmpData
    },
    async getFabricFactory () {
      try {
        const { data } = await FabricsFactoryService.getAll({ limit: 9999 })
        this.factoryData = data.results
      } catch (error) {
        console.error('getFabricFactory', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
    },
    isFormValid (index) {
      if (this.formValidates[index]) {
        return []
      }

      return [
        (v) => !!v || 'Field is require!'
      ]
    }
  }
}
</script>

<style>
.qty-field {
  width: 100%;
}

.fabric-drop-file .upload-file {
  height: 258px;
}

.delete-fabric-btn.v-btn {
  height: 40px !important;
}

.field-label {
  font-weight: bold;
}

.fabric-amount-input .v-messages__message {
  margin-top: 5px;
  font-size: 15px;
}
.uppercase input {
  text-transform: uppercase;
}
</style>
