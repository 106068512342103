export default (item) => {
  if (item && item.imageUrl) {
    return `transparent url(${item.imageUrl}) center/cover;`
  }
  if (item && Array.isArray(item.code) && item.code.length > 0) {
    const color = []
    const percent = parseFloat((100 / item.code.length).toFixed(2))
    let gradient = 0
    for (let i = 0; i < item.code.length; i++) {
      color.push(`${item.code[i]} ${gradient}%`)
      gradient += percent
      if ((item.code.length - 1) === i) {
        gradient = 100
      }
      color.push(`${item.code[i]} ${gradient}%`)
    }
    return `linear-gradient(135deg, ${color.join()});`
  }
  return null
}
