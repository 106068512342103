var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Materials")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-base-line"},[_c('search-box',{attrs:{"disabled":_vm.loading},on:{"on-search":function($event){return _vm.getMaterials(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.materials,"items-per-page":_vm.filters.limit,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.imageUrls.length)?_c('gw-product-image',{staticClass:"cursor-pointer py-3",attrs:{"src":item.imageUrls[0]},on:{"click":function($event){return _vm.previewImg(item.imageUrls[0])}}}):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size || '-')+" ")]}},{key:"item.material",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.material || '-')+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.color || '-')+" ")]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.unitPrice))+" "+_vm._s(item.currency.toUpperCase())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.onSelect(item)}}},[_vm._v(" Select ")])]}}],null,true)}),_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.pageCount,"disabled":_vm.loading},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}),_c('img-popup-preview',{attrs:{"img-src":_vm.currentImgPreview},model:{value:(_vm.previewEnabled),callback:function ($$v) {_vm.previewEnabled=$$v},expression:"previewEnabled"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }