<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="70%">
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-row
              justify="space-between"
              align="center">
              <v-col cols="auto">
                <h2>Fabrics</h2>
              </v-col>
              <v-col cols="auto">
                <div class="d-flex flex-row justify-end align-base-line">
                  <search-box
                    v-model="filters.search"
                    :disabled="loading"
                    @on-search="getFabrics(true)" />
                </div>
              </v-col>
            </v-row>
            <v-data-table
              class="mt-5"
              :headers="fabricHeaders"
              :items="fabrics"
              :items-per-page="filters.limit"
              :loading="loading"
              hide-default-footer>
              <template #[`item.image`]="{ item }">
                <gw-product-image
                  v-if="item.imageUrls.length"
                  :src="item.imageUrls[0]"
                  class="cursor-pointer py-3"
                  @click="previewImg(item.imageUrls[0])" />
              </template>
              <template #[`item.size`]="{ item }">
                {{ item.size || '-' }}
              </template>
              <template #[`item.color`]="{ item }">
                {{ item.color || '-' }}
              </template>
              <template #[`item.unitPrice`]="{ item }">
                {{ item.unitPrice | showFullPriceFormat() }} {{ item.currency.toUpperCase() }}
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  color="secondary"
                  outlined
                  :loading="loading"
                  @click="onSelect(item)">
                  Select
                </v-btn>
              </template>
            </v-data-table>
            <v-pagination
              v-model="filters.page"
              :total-visible="7"
              :length="pageCount"
              :disabled="loading" />
            <img-popup-preview
              v-model="previewEnabled"
              :img-src="currentImgPreview" />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import FabricsProvider from '@/resources/FabricsProvider'
import SearchBox from '@/components/SearchBox.vue'
import ImgPopupPreview from '@/components/ImgModalPreview.vue'

const FabricsService = new FabricsProvider()

export default {
  components: {
    SearchBox,
    ImgPopupPreview
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: [],
      loading: false,
      previewEnabled: false,
      currentImgPreview: null,
      fabrics: [],
      pageCount: 0,
      filters: {
        search: '',
        page: 1,
        limit: 10
      },
      fabricHeaders: [
        {
          text: '',
          value: 'image',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสแฮงค์',
          value: 'hangId',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสผ้า',
          value: 'fabricNo',
          align: 'center',
          sortable: false
        },
        {
          text: 'สถานที่สั่ง',
          value: 'factory',
          align: 'center',
          sortable: false
        },
        {
          text: 'ขนาด',
          value: 'size',
          align: 'center',
          sortable: false
        },
        {
          text: 'สี',
          value: 'color',
          align: 'end',
          sortable: false
        },
        {
          text: 'ราคาต่อหน่วย',
          value: 'unitPrice',
          align: 'center',
          sortable: false
        },
        {
          text: 'คอลเลคชั่น',
          value: 'collectionName',
          align: 'center',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'filters.page': {
      handler () {
        this.getFabrics()
      },
      deep: true
    }
  },
  mounted () {
    this.getFabrics(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getFabrics (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.fabrics = []
          this.filters.page = 1
        }

        const { data } = await FabricsService.getAll({
          search: this.filters.search,
          page: this.filters.page,
          limit: this.filters.limit
        })

        this.fabrics = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getFabrics', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    onSelect (item) {
      const items = {
        ...item,
        id: item._id,
        patterns: [
          {
            note: '',
            imageUrls: []
          }
        ]
      }

      this.$emit('on-select', items)
    },
    closeForm () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.uppercase {
  text-transform: uppercase;
}
</style>
